// import React, { useState } from 'react'
// // import {FoFIcosn} from 'assets/Link/FoF-Logo.png'
// import FoFIcosn from '../assets/FoF-Logo.png'
// import Scarab from '../assets/Link-Hit-Logo.png'
// import StartGame from '../assets/DoradoPressPlay.png'
// import { Link, useNavigate } from 'react-router-dom'
// import { toast, ToastContainer } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css';

// const GameLobby = () => {

//     const [game, setGame] = useState('')
//     const navigate = useNavigate()


//     const gameListData = [
//         {
//             id: 0,
//             icon: FoFIcosn,
//             name: 'Flames of Fortune',
//             path: '/flames-of-fortune'
//         },
//         {
//             id: 1,
//             icon: Scarab,
//             name: 'Flames of Fortune1',
//             path: '/slot2'
//         },
//         {
//             id: 2,
//             icon: FoFIcosn,
//             name: 'Flames of Fortune2',
//             path: '/slot3'
//         }]


//     const handlePlayGame=(id)=>{
//         const token = localStorage.getItem("token");
//         const selectedPath = gameListData[id].path;
//         setGame(selectedPath)
//         navigate(token ? selectedPath : '/login')
//     }
//     return (
//         <>
//             <ToastContainer />

//             <div className='mainLobby'>
//                 <h1 className='welcome-text'>Welcome</h1>
//                 <div className='gameCards'>
//             {
//                 gameListData.map((item, id)=>
//                     <div className='cards' key={id} onClick={() => handlePlayGame(id)}>
//                         <img src={item.icon} alt='FoF Image' />
//                     </div>
//                 )
//             }
//             </div>
//             {/* <button className='starBtn'>
//             <img src={StartGame} alt='Start Game' />
//             </button> */}
//             </div>

//         </>
//     )
// }

// export default GameLobby



import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import logo from '../assets/logo/logo.png'
import navCoin from '../assets/logo/navCoin.png'
import setting from '../assets/logo/setting.png'
import headerCoin from '../assets/logo/headerCoin.png';
import cardCoin from '../assets/logo/cardCoin.png'
import coinsList from '../assets/logo/coinsList.png'
import profile from '../assets/logo/profile.png'
import game from '../assets/logo/game.svg'
import sound from '../assets/logo/sound.svg'
import fire from '../assets/logo/fire.png'
import girl from '../assets/logo/girl.png'
import commingSoon from '../assets/logo/commingSoon.png'
export default function GameLobby() {
    const [game, setGame] = useState('')
    const navigate = useNavigate()
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const token = localStorage.getItem("token");
    const selectedPath = window.location.pathname;
    
    

        const handlePlayGame=()=>{
        const token = localStorage.getItem("token");
        navigate(token ? '/flames-of-fortune' : '/')
    }
    return (
        <>
            <header>
                <div className='header-wrapper'>
                    <div className='coin-number'><img src={headerCoin} alt="" /><span>$848432823</span></div>
                    <div className='header-logo'><img src={logo} alt="" /></div>
                    <div className='header-tabs'>
                        <div className="icon"><img src={navCoin} alt="" width={50} height={50} /></div>
                        <div className="user-profile"><img src={setting} alt="" width={30} height={30} /></div>
                        <div className="setting-icon"><img src={profile} alt="" width={50} height={50} /><span> Mr. James Bond</span></div>
                    </div>
                </div>
            </header>
            <section className='carousel-section'>
                <div className="carousel-sound-icon">

                    <img src={game} alt="" /><img src={sound} alt="" />

                </div>
                <Carousel responsive={responsive}>
                    <div>
                        <div className='carousel-card card-1'>
                            <div className="carousel-header">
                                <div className='card-icon-1'><img src={cardCoin} alt="" /></div>
                                <div className='dot-line'>
                                    <div className='dots'>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                    <span className="card-title">GRAND</span>
                                    <span className='coins'>$38593480</span>

                                </div>
                                <div className='card-icon-2'><img src={coinsList} alt="" /></div>
                            </div>
                            <div className="card-wrapper" >
                                <div className="cards-inner">
                                    <div className='cards-image'><img src={fire} alt="" /></div>
                                    <button type='button' className='play-btn' onClick={handlePlayGame}>PLAY</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='carousel-card card-2'>
                            <div className="carousel-header">
                                <div className='card-icon-1'><img src={cardCoin} alt="" /></div>
                                <div className='dot-line'>
                                    <div className='dots'>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                    <span className="card-title">GRAND</span>
                                    <span className='coins'>$38593480</span>

                                </div>
                                <div className='card-icon-2'><img src={coinsList} alt="" /></div>
                            </div>
                            <div className="card-wrapper">
                                <div className="cards-inner">
                                    <div className='cards-image'><img src={girl} alt="" /></div>
                                    <button type='button' className='play-btn'>PLAY</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='carousel-card card-3'>
                            <div className="carousel-header">
                                <div className='card-icon-1'><img src={cardCoin} alt="" /></div>
                                <div className='dot-line'>
                                    <div className='dots'>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                    <span className="card-title">GRAND</span>
                                    <span className='coins'>$38593480</span>

                                </div>
                                <div className='card-icon-2'><img src={coinsList} alt="" /></div>
                            </div>
                            <div className="card-wrapper">
                                <div className="cards-inner">
                                    <div className='cards-image'><img src={commingSoon} alt="" /></div>
                                    <button type='button' className='play-btn'>PLAY</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className='carousel-card card-4'>
                            <div className="carousel-header">
                                <div className='card-icon-1'><img src={cardCoin} alt="" /></div>
                                <div className='dot-line'>
                                    <div className='dots'>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                    <span className="card-title">GRAND</span>
                                    <span className='coins'>$38593480</span>

                                </div>
                                <div className='card-icon-2'><img src={coinsList} alt="" /></div>
                            </div>
                            <div className="card-wrapper">
                                <div className="cards-inner">
                                    <div className='cards-image'><img src={commingSoon} alt="" /></div>
                                    <button type='button' className='play-btn'>PLAY</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='carousel-card card-1'>
                            <div className="carousel-header">
                                <div className='card-icon-1'><img src={cardCoin} alt="" /></div>
                                <div className='dot-line'>
                                    <div className='dots'>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                    <span className="card-title">GRAND</span>
                                    <span className='coins'>$38593480</span>

                                </div>
                                <div className='card-icon-2'><img src={coinsList} alt="" /></div>
                            </div>
                            <div className="card-wrapper">
                                <div className="cards-inner">
                                    <div className='cards-image'><img src={commingSoon} alt="" /></div>
                                    <button type='button' className='play-btn'>PLAY</button>
                                </div>
                            </div>
                        </div>
                    </div>


                </Carousel>
            </section>

        </>
    )
}
