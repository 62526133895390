import './App.css';
import Slot from './Components/Slot';
import Login from './Pages/login/Login';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import PrivateRoute from './Pages/private/PrivateRoute';
import GameLobby from './Pages/GameLobby';
import GameHistoryTable from './Pages/GameHistoryTable';
// import { FreeSpin } from './Components/FreeSpin';

function App() {
  return (
    <>
      <BrowserRouter>
        {/* <RemoveScroll> */}
          <Routes>
            <Route path="/gameLobby" element={<GameLobby />} />
            <Route path="/" element={<Login />} />
            <Route element={<PrivateRoute />}>
              <Route path="/flames-of-fortune" element={<Slot />} />
            </Route>
            <Route path="/GameHistoryTable" element={<GameHistoryTable />} />
          </Routes>
        {/* </RemoveScroll> */}
      </BrowserRouter>
      {/* <FreeSpin /> */}
    </>
  );
}

export default App;
